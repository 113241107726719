import MuiTheme from "theme/MuiTheme";
import Head from "next/head";
import Router from "next/router";
import nProgress from "nprogress";
import "nprogress/nprogress.css";
import React, { Fragment, useEffect } from "react";
import createEmotionCache from "../src/createEmotionCache";
import { CacheProvider } from "@emotion/react";
import { AppProvider } from "../src/contexts/app/AppContext";
import api from '../src/api';
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import '../public/spinner.css'
import { Fab } from "@mui/material";
import Script from "next/script"
import { WhatsApp } from "@mui/icons-material";
import { useState } from "react";
import BotaliteChat from "../src/components/BotaliteScripts";
import Link from "next/link";
import { GoogleTagManager } from '@next/third-parties/google'


const clientSideEmotionCache = createEmotionCache();
Router.events.on("routeChangeStart", () => nProgress.start());
Router.events.on("routeChangeComplete", () => nProgress.done());
Router.events.on("routeChangeError", () => nProgress.done());

nProgress.configure({
	showSpinner: false,
});


const App = ({ Component, emotionCache = clientSideEmotionCache, pageProps }) => {
	const Layout = Component.layout || Fragment;

	useEffect(() => {
		// Remove the server-side injected CSS.
		const jssStyles = document.querySelector("#jss-server-side");

		if (jssStyles) {
			jssStyles.parentElement.removeChild(jssStyles);
		}
		api.post('/analytics/visit');

	}, []);

	const [elementHeight, setElementHeight] = useState(0);

	useEffect(() => {
		// Function to update the element height
		const updateElementHeight = () => {
			const element = document.getElementsByClassName('CookieConsent');
			if (element[0]) {
				setElementHeight(element[0].clientHeight);
				return true;
			} else {
				if (getCookieConsentValue()) {
					setElementHeight(10);
				}
				return false;
			}
		};

		// Initial call to set the initial height
		const heightAdjusted = updateElementHeight();

		if (!heightAdjusted) {
			let interval = setInterval(() => {
				const heightAdjusted = updateElementHeight();
				if (heightAdjusted) {
					clearInterval(interval);
				}
			}, 50);
		}
		// Event listener for window resize
		window.addEventListener('resize', updateElementHeight);

		// Cleanup: remove the event listener when the component is unmounted
		return () => {
			window.removeEventListener('resize', updateElementHeight);
		};
	}, []);

	return (
		<CacheProvider value={emotionCache}>
			<Head>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
			</Head>
			<AppProvider>
				<MuiTheme >
					<Layout>
						<Component {...pageProps} />
					</Layout>
					{/* <CookieConsent
						id="cookieConsent"
						buttonText="Accepteren en doorgaan"
						enableDeclineButton
						onDecline={() => { location.replace("/cookies"); acc(); }}
						declineButtonText="Meer info"
						flipButtons={true}
						onAccept={() => { location.reload() }}
						declineButtonStyle={{ background: "#D23F57", borderRadius: 5, border: "none", padding: 10 }}
						style={{ background: "#030E47" }}
						buttonStyle={{ background: "white", borderRadius: 5, border: "none", padding: 10 }}>
						Wij gebruiken cookies op onze website om u de meest relevante ervaring te geven. <br></br>
						Door op "Alles accepteren" te klikken, stemt u in met het gebruik van alle cookies.
					</CookieConsent> */}

					<Link href="https://wa.me/32497855255" target="_blank" passHref={true}>
						<Fab aria-label="add" variant="extended" sx={{
							position: 'fixed',
							bottom: getCookieConsentValue() ? 10 : (elementHeight + 10),
							right: 10,
							backgroundColor: '#25d366',
							transition: 'right 0.2s ease-in-out', // Add a smooth transition effect
							'&:hover': {
								backgroundColor: '#25d366',
							},
						}}>
							<WhatsApp style={{ color: 'white' }} />
							<span style={{ marginLeft: 2, color: 'white' }}>Tip ons</span>
						</Fab>
					</Link>
				</MuiTheme>
				{/* <BotaliteChat ></BotaliteChat> */}
			</AppProvider>
			<GoogleTagManager gtmId="GTM-5XN85MN"></GoogleTagManager>
			<GoogleTagManager gtmId="GTM-KB7TH4X6"></GoogleTagManager>
		</CacheProvider >

	);
};


export default App;